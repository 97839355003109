export default function Filters({filter_heading, filterList, list, selected, removeAll = !selected, col=6}) {
    let new_list = [...list].filter(i=>i).sort()

    function onClick(e) {
        filterList(e.target.value)
    }

    return (
        <div className='filters'>
            <div className="row filter-header">
                <div className="col">
                    {filter_heading}
                </div>
            </div>
            <div className="row filter-body">
                {(removeAll ? new_list: [...new_list, '']).map((list_item, index) => {
                    return (
                        <div className={`col-${col} col`} key={index}>
                            <div className={`row ${selected === list_item ? 'filter-item': ''}`} key={index}>
                                <div className="col-2 text-right">
                                    <input type='radio' name={filter_heading} id={list_item || 'All'} value={list_item} onClick={onClick} checked={list_item===selected}/>
                                </div>
                                <div className="col-8 text-left filter-label">
                                    <label className='' htmlFor={list_item || 'All'}>{list_item || 'All'}</label>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
