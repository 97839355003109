import React, { useState } from 'react';
import Filters from './Filters';

function MobileFilter({ 
    category, 
    cl, 
    brand, 
    type, 
    filterCategory, 
    filterClass, 
    filterBrand, 
    filterType, 
    listOfCategories, 
    listOfClass, 
    listOfBrands, 
    listOfTypes 
}) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mobile-filter">
            <button className="mobile-filter-toggle" onClick={() => setIsOpen(!isOpen)}> <i className='fa fa-sliders'/>
                {isOpen ? 'Close Filters' : 'Filter Items'}
            </button>
            {isOpen && (
                <div className="mobile-filter-content">
                    <Filters filterList={filterCategory} filter_heading={`Item Categories`} list={listOfCategories()} selected={category} removeAll={true} col={12}/>
                    <Filters filterList={filterClass} filter_heading={`${category} Classes`} list={listOfClass()} selected={cl}/>
                    <Filters filterList={filterBrand} filter_heading={`${category} Brands`} list={listOfBrands()} selected={brand}/>
                    <Filters filterList={filterType} filter_heading={`${category} ${category === 'Tyre' ? 'Sizes' : 'Types'}`} list={listOfTypes()} selected={type}/>
                </div>
            )}
        </div>
    );
}

export default MobileFilter;
